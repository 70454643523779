export enum GdprFormControlNameEnum {
  ATOZ_NEWS_CONSENT = 'atozNewsConsent',
  ATOZ_EVENTS_CONSENT = 'atozEventsConsent',
  DEVECO_CONSENT = 'devecoConsent',
  DEVECO_NEWS_CONSENT = 'devecoNewsConsent',
  DEVECO_EVENTS_CONSENT = 'devecoEventsConsent',
  DECLINE_UNSUBSCRIBE = 'declineUnsubscribe',
  DOT_DIGITAL_CONSENT = 'dotDigitalConsent',
  TITLE = 'title',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  COMPANY_NAME = 'companyName'
}
