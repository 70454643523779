import {GdprResponseModel} from "./gdpr-response.model";
import moment from "moment";

export class GdprRequestDetailsModel {
  id: number;
  createdAt: moment.Moment;
  expirationDate: moment.Moment;
  gdprRequestVersion: number
  gdprResponse?: GdprResponseModel | null;
  cancellationDate?: moment.Moment | null;

  constructor(
    id: number,
    createdAt: moment.Moment,
    expirationDate: moment.Moment,
    gdprRequestVersion: number,
    gdprResponse?: GdprResponseModel | null,
    cancellationDate?: moment.Moment | null
  ) {
    this.id = id;
    this.gdprResponse = gdprResponse;
    this.createdAt = moment(createdAt);
    this.expirationDate = moment(expirationDate);
    this.gdprRequestVersion = gdprRequestVersion;
    this.cancellationDate = cancellationDate ? moment(cancellationDate): null;
  }
}
